
















import { Component, Vue } from 'vue-property-decorator'
import delay from 'delay'

@Component({
  mounted () {
    delay(1000)
      .then(() => {
        this.$router.push('/dashboard')
      })
  }
})
export default class OwnerDashboardLoading extends Vue {

}
